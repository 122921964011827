import React from "react";
import { up } from "styled-breakpoints";

import styled from "styled-components";
import Section from "../../components/Section";
import { Container } from "../../components/Container";
import Image from "../../components/Image";

const Title = styled.h1`
  font-family: ${({ theme }) => theme.fonts.secondary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;
  word-wrap: break-word;
  margin: 0;

  ${up("lg")} {
    font-size: 42px;
    line-height: 50px;
  }
`;

const ImageContainer = styled.div`
  width: 193px;
  height: auto;
`;

const ImageBox = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeroBackground = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${up("lg")} {
    height: 240px;
  }
`;

const HeroContainer = styled(Container)`
  display: grid;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: 50px 20px;
  grid-template-columns: 93px 1fr;
  grid-column-gap: 10px;

  ${up("lg")} {
    grid-template-columns: 193px 1fr;
    grid-column-gap: 50px;
    padding: 0 20px;
  }
`;

const HeroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
`;

const SmallTitle = styled.div`
  color: ${({ theme }) => theme.colors.orange};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;

const HeroSection = () => {
  return (
    <Section.Container id="" variant="dark" disableVerticalMargins>
      <HeroBackground>
        <HeroContainer>
          <ImageBox>
            <ImageContainer>
              <Image filename="create-account/user-lg.png" alt="User" />
            </ImageContainer>
          </ImageBox>
          <HeroTextContainer>
            <SmallTitle>Passo 1:</SmallTitle>
            <Title>Faça sua Adesão.</Title>
          </HeroTextContainer>
        </HeroContainer>
      </HeroBackground>
    </Section.Container>
  );
};

export default HeroSection;
