import React, { useEffect, useState } from "react";
import Section from "../../components/Section";
import styled from "styled-components";
import { Container } from "../../components/Container";
import { Select } from "../../components/Select/Select";
import { Button } from "../../components/Button";
import { useForm, Controller } from "react-hook-form";
import {
  GET_STATES,
  GET_CITIES_BY_STATE,
  GET_SYNDICATES_BY_CITY_ID,
  FRONT_END_COMPLETE_REGISTRATION_URL,
} from "../../API/ApiConstants";
import queryString from "query-string";
import { navigate } from "gatsby";

const CreateAccountContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 620px;
  margin: 0 auto;
`;

const CheckBox = styled.input`
  margin-right: 15px;
  width: 22px;
  height: 22px;
  border: none;
`;

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  &:checked ~ &:after {
    display: block;
  }
`;

const CheckMarkIcon = styled.span`
  font-family: ${({ theme }) => theme.fonts.primary};

  a {
    color: ${({ theme }) => theme.colors.blue};
    text-decoration: none;
    font-weight: 700;
    cursor: pointer;
  }

  &:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const CreateAccountForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;
  width: 100%;
`;

const ContinueButton = styled(Button)`
  padding: 18px 0;
`;

const CreateAccount = () => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [syndicates, setSyndicates] = useState([]);

  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingSyndicates, setLoadingSyndicates] = useState(false);

  const { handleSubmit, setValue, errors, getValues, control } = useForm({
    reValidateMode: "onSubmit",
  });

  const onSubmit = async (data) => {
    console.log(data);
    data.termsAndConditions = true;
    if (data.termsAndConditions === true) {
      // console.log(`${FRONT_END_COMPLETE_REGISTRATION_URL}?${queryString.stringify(data)}`)
      navigate(
        `${FRONT_END_COMPLETE_REGISTRATION_URL}?${queryString.stringify(data)}`
      );
    }
  };

  async function getStates() {
    setLoadingStates(true);
    const response = await fetch(GET_STATES);

    const { data: states } = await response.json();

    setLoadingStates(false);

    setStates(states);
  }

  const loadCitiesFromSelectedState = async (event) => {
    setLoadingCities(true);

    const selectedStateValue = event.target.value;
    setValue("state", selectedStateValue);

    const state = getValues("state");

    const response = await fetch(
      `${GET_CITIES_BY_STATE}?${queryString.stringify({
        stateId: state,
      })}`
    );

    const { data: cities } = await response.json();

    setLoadingCities(false);

    setCities(cities);
  };

  const loadSyndicatesFromSelectedCity = async (event) => {
    setLoadingSyndicates(true);

    const selectedCityId = event.target.value;

    setValue("city", selectedCityId);

    const response = await fetch(
      `${GET_SYNDICATES_BY_CITY_ID}?${queryString.stringify({
        cityId: getValues("city"),
      })}`
    );

    const { data: syndicates } = await response.json();

    setLoadingSyndicates(false);
    setSyndicates(syndicates);
  };

  const setSyndicate = async (event) => {
    const selectedSyndicateId = event.target.value;

    setValue("syndicate", selectedSyndicateId);
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <Section.Container id="" variant="intermediate">
      <Container>
        <Section.Header
          name="Para criar sua conta, preencha os dados abaixo e depois clique em Continuar:"
          variant="intermediate"
        ></Section.Header>

        <CreateAccountContainer>
          <CreateAccountForm onSubmit={handleSubmit(onSubmit)}>
            <Controller
              id="state"
              name="state"
              title="Estado"
              error={errors.state}
              render={({ onBlur, value, name, id, title, error }) => (
                <Select
                  id={id}
                  name={name}
                  title={title}
                  onChange={loadCitiesFromSelectedState}
                  onBlur={onBlur}
                  value={value}
                  required
                  loading={loadingStates}
                >
                  <option selected="true" disabled="disabled">
                    Selecione o estado
                  </option>
                  {states.map((state) => (
                    <option value={state.stateId}>{state.name}</option>
                  ))}
                </Select>
              )}
              control={control}
            />

            <Controller
              id="city"
              name="city"
              title="Cidade"
              error={errors.city}
              render={({ onBlur, value, id, name, title }) => (
                <Select
                  id={id}
                  name={name}
                  title={title}
                  onChange={loadSyndicatesFromSelectedCity}
                  onBlur={onBlur}
                  value={value}
                  required
                  loading={loadingCities}
                >
                  <option selected="true" disabled="disabled">
                    Selecione a cidade
                  </option>
                  {cities.map((city) => (
                    <option value={city.cityId}>{city.name}</option>
                  ))}
                </Select>
              )}
              control={control}
            />

            <Controller
              id="syndicate"
              name="syndicate"
              title="Sindicato"
              error={errors.syndicate}
              render={({ onBlur, value, id, name, title }) => (
                <Select
                  id={id}
                  name={name}
                  title={title}
                  onChange={setSyndicate}
                  onBlur={onBlur}
                  value={value}
                  required
                  loading={loadingSyndicates}
                >
                  <option selected="true" disabled="disabled">
                    Selecione o sindicato
                  </option>
                  {syndicates.map((syndicate) => (
                    <option value={syndicate.syndicateId}>
                      {syndicate.fantasyName}
                    </option>
                  ))}
                </Select>
              )}
              control={control}
            />

            {/* <Controller
              id="termsAndConditions"
              name="termsAndConditions"
              error={errors.termsAndConditions}
              render={({ onChange, value, id, name }) => (
                <CheckBoxLabel htmlFor="termsAndConditions">
                  <CheckBox
                    id={id}
                    name={name}
                    type="checkbox"
                    onChange={(e) => {
                      onChange(e.target.checked);
                    }}
                    checked={value}
                  />

                  <CheckMarkIcon>
                    Concordo com os{" "}
                    <a
                      onClick={() => {
                        const pdfUrl =
                          "https://api-dev.colaboradoronline.com.br/files/edbe00b3-4f5a-447f-9b6e-e1e017dc4871.pdf";
                        window.open(pdfUrl, "_blank");
                      }}
                    >
                      Termos de Uso
                    </a>{" "}
                    deste site.
                  </CheckMarkIcon>
                </CheckBoxLabel>
              )}
              control={control}
              defaultValue={false}
            /> */}

            <ContinueButton primary type="submit">
              Continuar
            </ContinueButton>
          </CreateAccountForm>
        </CreateAccountContainer>
      </Container>
    </Section.Container>
  );
};

export default CreateAccount;
