import React from "react";

import { Header } from "../components/Header";
import { SEO } from "../components/SEO";
import Footer from "../components/Footer";
import { Layout } from "../components/Layout";
import Hero from "../sections/CreateAccount/Hero";
import CreateAccount from "../sections/CreateAccount/CreateAccount";
import Features from "../sections/Features";
import ClientTestimonials from "../sections/ClientTestimonials";
import Newsletter from "../sections/Newsletter";

const CreateAccountPage = () => {
  const pageSEO = {
    title: "Criar conta",
    description:
      "Comece a garantir seus direitos trabalhistas de forma fácil e segura.",
  };

  return (
    <Layout>
      <SEO page={pageSEO} />
      <Header />
      <Hero />
      {/* <Features /> */}
      <CreateAccount />
      {/* <ClientTestimonials /> */}
      <Newsletter variant="intermediate" />
      <Footer />
    </Layout>
  );
};

export default CreateAccountPage;
