import React, { useState } from "react";
import styled, { css } from "styled-components";
import caretDownIcon from "../../assets/images/icons/caret-down.png";
import Loading from "../../assets/images/icons/loading.inline.svg";

export const ErrorTextStyle = css`
  color: ${({ theme }) => theme.colors.error};
`;

export const LabelStyle = css`
  text-align: left;
  font: Regular 14px/34px Assistant;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
  transition: 0.2s;
`;

export const Label = styled.label`
  ${LabelStyle};
`;

export const SelectInput = styled.select`
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 4px;
  font-size: 16px;
  padding: 10px 15px;
  padding-right: 50px;
  margin-top: 5px;
  width: 100%;
  outline: none;
  cursor: pointer;
  position: relative;

  background: url(${caretDownIcon}) no-repeat right
    ${({ theme }) => theme.colors.white};
  -webkit-appearance: none;
  background-position-x: calc(100% - 20px);
`;

export const SelectContainer = styled.div`
  width: 100%;
  margin: 0;
  border-radius: 4px;
  position: relative;
`;

const LoadingImage = styled(Loading)`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 13px;
  right: -30px;
`;

export const Select = ({
  id,
  name = "",
  title = "",
  value = "",
  error,
  invertColors = false,
  required = false,
  onChange,
  onBlur,
  children,
  loading = false,
}) => {
  const [selectFocused, toggleSelectFocus] = useState(false);

  const handleFocus = () => {
    toggleSelectFocus(!selectFocused);
  };

  const handleBlur = (event) => {
    toggleSelectFocus(!selectFocused);
    onBlur(event);
  };

  const isFilledOrActive = () => {
    return selectFocused || value;
  };

  return (
    <>
      <SelectContainer>
        <Label
          htmlFor={name}
          focused={isFilledOrActive()}
          error={error}
          invertColors={invertColors}
        >
          {title}
        </Label>
        <SelectInput
          id={id}
          name={name}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          focused={isFilledOrActive()}
          value={value}
          required={required}
          error={error}
          invertColors={invertColors}
          area-invalid={error}
        >
          {children}
        </SelectInput>
        {loading && <LoadingImage />}
      </SelectContainer>
    </>
  );
};
